<template>
  <b-modal
    id="modal-activity"
    :title="`${isEdit ? 'Edit' : 'New'} Activity Type`"
    hide-footer
    centered
    @hidden="resetModal"
  >
    <validation-observer ref="form" slim>
      <validation-provider
        name="Type"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Type"
          :invalid-feedback="errors[0]"
        >
          <custom-select
            v-model="form.budgetTypeId"
            label="typeName"
            placeholder="Select type"
            :options="typeList"
            :reduce="({ typeId }) => typeId"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Activity"
        rules="required|max:100"
        v-slot="{ errors }"
      >
        <b-form-group label="Activity" :invalid-feedback="errors[0]">
          <b-form-input
            placeholder="Enter activity name"
            v-model="form.name"
            trim
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <b-row>
        <b-col>
          Show in project expense?
        </b-col>
        <b-col class="text-right">
          <b-form-group>
            <b-form-checkbox v-model="form.isShowInProjectExpense" switch />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button v-if="!isEdit" class="next" variant="warning" block @click="submit">
        Save
      </b-button>
      <div v-else class=" d-flex justify-content-center">
        <b-button
          class="text-warning mt-2"
          variant="outline-warning"
          @click="cancel"
          block
        >
          Cancel
        </b-button>
        <b-button
          class="next ml-3"
          variant="warning"
          @click="submit"
          block
        >
          Update
        </b-button>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    form: {
      type: Object,
      default: () => {}
    },
    typeList: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    isBusy: false
  }),

  methods: {
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }
      if (this.isEdit) {
        this.$emit('update')
      } else {
        this.$emit('submit')
      }
    },
    resetModal() {
      this.$emit('reset')
    },
    cancel() {
      this.$bvModal.hide('modal-activity')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
