<template>
  <div>
    <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
      <b-row align-v="center">
        <b-col>
          <b-form-group
            label="Type"
          >
            <custom-select
              v-model="filter.budgetType"
              label="typeName"
              multiple
              placeholder="Select type"
              :options="typeList"
              :reduce="({ typeName }) => typeName"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <div class="button-project mt-2 d-flex justify-content-end">
            <b-button
              class="reset mr-3"
              variant="outline-warning"
              @click="resetFilter"
            >
              Reset Filter
            </b-button>
            <b-button
              class="next"
              variant="warning"
              @click="$refs.table.refresh()"
            >
              Apply Filter
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="mt-3 d-flex justify-content-between">
        <b-button
          class="next"
          variant="warning"
          v-b-modal.modal-activity
        >
          Add New Activity
        </b-button>
        <b-form-group class="m-0">
          <b-input-group>
            <b-form-input
              v-model="search"
              placeholder="Search..."
              @change="$refs.table.refresh()"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <br/>
      <b-row v-show="!items.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png"/>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        v-show="items.length"
        :items="fetchActivities"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        striped
        responsive
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(type)="{ item }">
          {{ item.budgetType.typeName }}
        </template>
        <template #cell(isShowInProjectExpense)="{ item }">
          <b-form-checkbox
            v-model="item.isShowInProjectExpense"
            disabled
          />
        </template>
        <template #cell(action)="{ item }">
          <div class="data">
            <b-link
              class="text-secondary mx-2"
              @click="goToEdit(item.id)"
            >
              <fa-icon icon="edit" />
            </b-link>
            <b-link
              class="text-secondary mx-2"
              @click="goToDelete(item)"
            >
              <fa-icon icon="trash-alt" />
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
          </span >
        </div>
      </div>
    </b-card>
    <modal-activity
      :is-edit="isEdit"
      :form="form"
      :type-list="typeList"
      @reset="resetForm"
      @submit="submitActivity"
      @update="updateActivity"
    />
    <modal-delete-activity
      :activity-name="formDelete.name"
      @remove="removeActivity"
    />
  </div>
</template>

<script>
import api from '@/api'
import ModalActivity from './ModalActivity.vue'
import ModalDeleteActivity from './ModalDeleteActivity.vue'

export default {
  components: {
    ModalActivity,
    ModalDeleteActivity
  },

  data: () => ({
    typeList: [],
    sortBy: '',
    search: '',
    filter: {
      budgetType: ''
    },
    busy: false,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'type', label: 'Types', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'name', label: 'Activities', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'isShowInProjectExpense', label: 'Show in project expense?', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    pageOptions: [10, 20, 50, 100],
    items: [],
    form: {
      budgetTypeId: null,
      name: null,
      isShowInProjectExpense: false
    },
    formDelete: {
      name: null,
      id: null
    },
    isEdit: false
  }),

  computed: {
    stringifiedFilters() {
      let str = this.search ? `searchTypeActivity==${this.search}` : ''
      for (const key in this.filter) {
        if (this.filter[key].length === 1) {
          str += `,${key}==${this.filter[key]}`
        } else if (this.filter[key].length > 1) {
          str += `,${key}==${this.filter[key].join('|')}`
        }
      }
      return str
    }
  },

  created() {
    this.fetchTypeOption()
  },

  methods: {
    async fetchActivities() {
      const { data } = await api.budget.list({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: `${this.stringifiedFilters}`,
        sorts: this.sortBy
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    async fetchTypeOption() {
      const { data } = await api.budget.budgetDefault()
      this.typeList = data.data.budgetTypes
    },
    resetFilter() {
      this.filter = this.$options.data().filter
      this.$refs.table.refresh()
    },
    async submitActivity() {
      try {
        await api.budget.submitActivity(this.form)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save Activity', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.$bvModal.hide('modal-activity')
          this.$refs.table.refresh()
        }, 2000)
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async goToEdit(id) {
      this.isEdit = true
      const { data } = await api.budget.getActivity(id)
      this.form = {
        ...data.data
      }

      this.$bvModal.show('modal-activity')
    },
    goToDelete(item) {
      this.formDelete = {
        ...item
      }
      this.$bvModal.show('modal-delete-activity')
    },
    async removeActivity() {
      try {
        await api.budget.deleteActivity(this.formDelete.id)
        this.$nextTick(() => {
          this.$bvToast.toast(`Success delete activity ${this.formDelete.name}`, {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async updateActivity() {
      try {
        await api.budget.updateActivity(this.form.id, this.form)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-activity')
          this.$bvToast.toast('Success update activity', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
          setTimeout(() => {
            this.isEdit = false
          }, 1500)
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    resetForm() {
      this.form = this.$options.data().form
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}
</style>
