<template>
  <b-modal
    id="modal-delete-activity"
    hide-header
    hide-footer
    centered
  >
    <div class="text-center">
      <img src="@/assets/close_up.png" />
    </div>
    <h4 class="text-danger font-weight-bold text-center">Delete {{ activityName }}</h4>
    <div class="text-center">Are you sure want to remove this activity?</div>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-warning"
        variant="outline-warning"
        @click="onClickBack"
      >
        No, Cancel
      </b-button>
      <b-button
        class="next ml-3"
        variant="warning"
        @click="remove"
      >
        Remove
      </b-button>
    </div>
  </b-modal>
</template>
<script>

export default {
  props: {
    activityName: {
      types: String,
      default: () => ''
    }
  },
  methods: {
    onClickBack() {
      this.$bvModal.hide('modal-delete-activity')
    },
    remove() {
      this.$bvModal.hide('modal-delete-activity')
      this.$emit('remove')
    }
  }
}
</script>
